body .wrapper .container {
    width: 100vw;
    height: 100vh;
    position: relative;
    top: 0;
    left: 0;
}
body .wrapper .container .dot {
    border-radius: 100%;
    position: absolute;
    opacity: 0;
    transition: top 2s ease-in-out, left 2s ease-in-out, opacity 2s ease-in-out;
}
@keyframes pulse-animation {
    0% {
        opacity: 1;
   }
    100% {
        width: 100vh;
        height: 100vh;
        opacity: 0;
   }
}
#pulse-animation:checked ~ .wrapper .container {
    background: rgba(81, 111, 199, 0.1);
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(1) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 6px rgba(28, 164, 51, 0.8) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 0.1s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(2) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 7px rgba(135, 132, 16, 0.4) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 0.2s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(3) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 7px rgba(171, 63, 110, 0.7) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 0.3s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(4) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 8px rgba(24, 89, 162, 0.4) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 0.4s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(5) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 10px rgba(157, 202, 42, 0.9) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 0.5s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(6) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 1px rgba(250, 150, 245, 0.6) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 0.6s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(7) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 7px rgba(186, 219, 31, 0.5) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 0.7s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(8) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 8px rgba(205, 154, 155, 0.3) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 0.8s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(9) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 9px rgba(221, 240, 8, 0.2) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 0.9s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(10) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 8px rgba(148, 187, 167, 0.7) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 1s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(11) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 9px rgba(97, 52, 134, 0.5) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 1.1s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(12) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 9px rgba(247, 179, 200, 0.7) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 1.2s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(13) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 7px rgba(63, 34, 192, 0.9) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 1.3s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(14) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 5px rgba(42, 108, 177, 0.7) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 1.4s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(15) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 4px rgba(134, 64, 216, 0.2) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 1.5s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(16) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 9px rgba(18, 190, 165, 0.9) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 1.6s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(17) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 8px rgba(156, 12, 87, 0.7) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 1.7s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(18) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 2px rgba(211, 166, 133, 0.1) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 1.8s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(19) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 3px rgba(233, 130, 90, 0.1) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 1.9s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(20) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 8px rgba(250, 198, 189, 0.4) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 2s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(21) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 1px rgba(8, 140, 7, 0.1) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 2.1s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(22) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 10px rgba(184, 81, 253, 0.8) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 2.2s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(23) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 9px rgba(127, 11, 245, 0.2) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 2.3s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(24) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 7px rgba(76, 212, 73, 0.7) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 2.4s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(25) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 10px rgba(111, 77, 227, 0.8) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 2.5s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(26) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 3px rgba(100, 98, 34, 0.2) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 2.6s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(27) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 8px rgba(206, 255, 181, 0.7) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 2.7s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(28) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 9px rgba(83, 43, 93, 0.4) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 2.8s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(29) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 4px rgba(226, 84, 127, 0.5) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 2.9s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(30) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 4px rgba(246, 41, 47, 0.2) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 3s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(31) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 6px rgba(222, 134, 162, 0.6) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 3.1s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(32) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 1px rgba(83, 186, 107, 0.4) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 3.2s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(33) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 3px rgba(213, 169, 182, 0.7) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 3.3s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(34) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 9px rgba(184, 166, 124, 0.3) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 3.4s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(35) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 9px rgba(236, 174, 206, 0.6) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 3.5s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(36) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 8px rgba(253, 197, 134, 0.5) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 3.6s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(37) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 9px rgba(149, 6, 34, 0.7) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 3.7s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(38) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 1px rgba(179, 15, 240, 0.9) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 3.8s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(39) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 1px rgba(239, 2, 155, 0.3) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 3.9s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(40) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 10px rgba(126, 209, 103, 1) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 4s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(41) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 2px rgba(16, 13, 217, 0.2) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 4.1s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(42) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 1px rgba(165, 44, 60, 0.5) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 4.2s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(43) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 10px rgba(176, 46, 8, 0.7) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 4.3s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(44) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 7px rgba(220, 211, 164, 0.8) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 4.4s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(45) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 2px rgba(77, 170, 239, 0.2) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 4.5s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(46) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 1px rgba(177, 94, 204, 0.2) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 4.6s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(47) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 6px rgba(62, 180, 19, 0.7) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 4.7s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(48) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 5px rgba(89, 207, 81, 0.3) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 4.8s;
}
#pulse-animation:checked ~ .wrapper .dot:nth-child(49) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border: 6px rgba(26, 59, 234, 0.6) solid;
    animation: pulse-animation 5s linear infinite;
    animation-delay: 4.9s;
}
@keyframes shower {
    0% {
        top: 0;
        opacity: 1;
   }
    100% {
        top: 100%;
        opacity: 0;
   }
}
#shower:checked ~ .wrapper .container {
    background: rgba(35, 43, 68, 0.7);
}
#shower:checked ~ .wrapper .dot:nth-child(1) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.1);
    left: 59vw;
    animation: shower 5s linear infinite;
    animation-delay: 0.2s;
}
#shower:checked ~ .wrapper .dot:nth-child(2) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.4);
    left: 86vw;
    animation: shower 5s linear infinite;
    animation-delay: 0.4s;
}
#shower:checked ~ .wrapper .dot:nth-child(3) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.7);
    left: 69vw;
    animation: shower 5s linear infinite;
    animation-delay: 0.6s;
}
#shower:checked ~ .wrapper .dot:nth-child(4) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.2);
    left: 67vw;
    animation: shower 5s linear infinite;
    animation-delay: 0.8s;
}
#shower:checked ~ .wrapper .dot:nth-child(5) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.7);
    left: 59vw;
    animation: shower 5s linear infinite;
    animation-delay: 1s;
}
#shower:checked ~ .wrapper .dot:nth-child(6) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.8);
    left: 14vw;
    animation: shower 5s linear infinite;
    animation-delay: 1.2s;
}
#shower:checked ~ .wrapper .dot:nth-child(7) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.3);
    left: 93vw;
    animation: shower 5s linear infinite;
    animation-delay: 1.4s;
}
#shower:checked ~ .wrapper .dot:nth-child(8) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.2);
    left: 73vw;
    animation: shower 5s linear infinite;
    animation-delay: 1.6s;
}
#shower:checked ~ .wrapper .dot:nth-child(9) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.6);
    left: 2vw;
    animation: shower 5s linear infinite;
    animation-delay: 1.8s;
}
#shower:checked ~ .wrapper .dot:nth-child(10) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.2);
    left: 36vw;
    animation: shower 5s linear infinite;
    animation-delay: 2s;
}
#shower:checked ~ .wrapper .dot:nth-child(11) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.1);
    left: 90vw;
    animation: shower 5s linear infinite;
    animation-delay: 2.2s;
}
#shower:checked ~ .wrapper .dot:nth-child(12) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.3);
    left: 29vw;
    animation: shower 5s linear infinite;
    animation-delay: 2.4s;
}
#shower:checked ~ .wrapper .dot:nth-child(13) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.2);
    left: 68vw;
    animation: shower 5s linear infinite;
    animation-delay: 2.6s;
}
#shower:checked ~ .wrapper .dot:nth-child(14) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.3);
    left: 4vw;
    animation: shower 5s linear infinite;
    animation-delay: 2.8s;
}
#shower:checked ~ .wrapper .dot:nth-child(15) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.9);
    left: 34vw;
    animation: shower 5s linear infinite;
    animation-delay: 3s;
}
#shower:checked ~ .wrapper .dot:nth-child(16) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.7);
    left: 46vw;
    animation: shower 5s linear infinite;
    animation-delay: 3.2s;
}
#shower:checked ~ .wrapper .dot:nth-child(17) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.8);
    left: 38vw;
    animation: shower 5s linear infinite;
    animation-delay: 3.4s;
}
#shower:checked ~ .wrapper .dot:nth-child(18) {
    top: 0;
    width: 20px;
    height: 20px;
    background: 255(108255 25525255 1);
    left: 67vw;
    animation: shower 5s linear infinite;
    animation-delay: 3.6s;
}
#shower:checked ~ .wrapper .dot:nth-child(19) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.1);
    left: 23vw;
    animation: shower 5s linear infinite;
    animation-delay: 3.8s;
}
#shower:checked ~ .wrapper .dot:nth-child(20) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.2);
    left: 33vw;
    animation: shower 5s linear infinite;
    animation-delay: 4s;
}
#shower:checked ~ .wrapper .dot:nth-child(21) {
    top: 0;
    width: 20px;
    height: 20px;
    background: 255(3255 25546255 1);
    left: 44vw;
    animation: shower 5s linear infinite;
    animation-delay: 4.2s;
}
#shower:checked ~ .wrapper .dot:nth-child(22) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.7);
    left: 41vw;
    animation: shower 5s linear infinite;
    animation-delay: 4.4s;
}
#shower:checked ~ .wrapper .dot:nth-child(23) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.4);
    left: 35vw;
    animation: shower 5s linear infinite;
    animation-delay: 4.6s;
}
#shower:checked ~ .wrapper .dot:nth-child(24) {
    top: 0;
    width: 20px;
    height: 20px;
    background: 255(233255 25578255 1);
    left: 7vw;
    animation: shower 5s linear infinite;
    animation-delay: 4.8s;
}
#shower:checked ~ .wrapper .dot:nth-child(25) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.8);
    left: 13vw;
    animation: shower 5s linear infinite;
    animation-delay: 5s;
}
#shower:checked ~ .wrapper .dot:nth-child(26) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.8);
    left: 26vw;
    animation: shower 5s linear infinite;
    animation-delay: 5.2s;
}
#shower:checked ~ .wrapper .dot:nth-child(27) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.6);
    left: 47vw;
    animation: shower 5s linear infinite;
    animation-delay: 5.4s;
}
#shower:checked ~ .wrapper .dot:nth-child(28) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.2);
    left: 88vw;
    animation: shower 5s linear infinite;
    animation-delay: 5.6s;
}
#shower:checked ~ .wrapper .dot:nth-child(29) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 1);
    left: 29vw;
    animation: shower 5s linear infinite;
    animation-delay: 5.8s;
}
#shower:checked ~ .wrapper .dot:nth-child(30) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.6);
    left: 42vw;
    animation: shower 5s linear infinite;
    animation-delay: 6s;
}
#shower:checked ~ .wrapper .dot:nth-child(31) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.3);
    left: 94vw;
    animation: shower 5s linear infinite;
    animation-delay: 6.2s;
}
#shower:checked ~ .wrapper .dot:nth-child(32) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.6);
    left: 56vw;
    animation: shower 5s linear infinite;
    animation-delay: 6.4s;
}
#shower:checked ~ .wrapper .dot:nth-child(33) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.9);
    left: 51vw;
    animation: shower 5s linear infinite;
    animation-delay: 6.6s;
}
#shower:checked ~ .wrapper .dot:nth-child(34) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 1);
    left: 48vw;
    animation: shower 5s linear infinite;
    animation-delay: 6.8s;
}
#shower:checked ~ .wrapper .dot:nth-child(35) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.7);
    left: 45vw;
    animation: shower 5s linear infinite;
    animation-delay: 7s;
}
#shower:checked ~ .wrapper .dot:nth-child(36) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.1);
    left: 89vw;
    animation: shower 5s linear infinite;
    animation-delay: 7.2s;
}
#shower:checked ~ .wrapper .dot:nth-child(37) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.3);
    left: 91vw;
    animation: shower 5s linear infinite;
    animation-delay: 7.4s;
}
#shower:checked ~ .wrapper .dot:nth-child(38) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.7);
    left: 73vw;
    animation: shower 5s linear infinite;
    animation-delay: 7.6s;
}
#shower:checked ~ .wrapper .dot:nth-child(39) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 1);
    left: 18vw;
    animation: shower 5s linear infinite;
    animation-delay: 7.8s;
}
#shower:checked ~ .wrapper .dot:nth-child(40) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.1);
    left: 81vw;
    animation: shower 5s linear infinite;
    animation-delay: 8s;
}
#shower:checked ~ .wrapper .dot:nth-child(41) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.3);
    left: 70vw;
    animation: shower 5s linear infinite;
    animation-delay: 8.2s;
}
#shower:checked ~ .wrapper .dot:nth-child(42) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.2);
    left: 26vw;
    animation: shower 5s linear infinite;
    animation-delay: 8.4s;
}
#shower:checked ~ .wrapper .dot:nth-child(43) {
    top: 0;
    width: 20px;
    height: 20px;
    background: 255(37255 25554255 1);
    left: 49vw;
    animation: shower 5s linear infinite;
    animation-delay: 8.6s;
}
#shower:checked ~ .wrapper .dot:nth-child(44) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.7);
    left: 84vw;
    animation: shower 5s linear infinite;
    animation-delay: 8.8s;
}
#shower:checked ~ .wrapper .dot:nth-child(45) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.1);
    left: 24vw;
    animation: shower 5s linear infinite;
    animation-delay: 9s;
}
#shower:checked ~ .wrapper .dot:nth-child(46) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.9);
    left: 65vw;
    animation: shower 5s linear infinite;
    animation-delay: 9.2s;
}
#shower:checked ~ .wrapper .dot:nth-child(47) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.9);
    left: 67vw;
    animation: shower 5s linear infinite;
    animation-delay: 9.4s;
}
#shower:checked ~ .wrapper .dot:nth-child(48) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.3);
    left: 10vw;
    animation: shower 5s linear infinite;
    animation-delay: 9.6s;
}
#shower:checked ~ .wrapper .dot:nth-child(49) {
    top: 0;
    width: 20px;
    height: 20px;
    background: rgba(255,255,255, 0.7);
    left: 31vw;
    animation: shower 5s linear infinite;
    animation-delay: 9.8s;
}
/* #spread:checked ~ .wrapper .container {
    background: rgba(216, 60, 244, 0.2);
} */
@keyframes spread1 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 67vh;
        left: -91vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(1) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.1);
    width: 24px;
    height: 24px;
    animation-delay: 0.1s;
    animation-name: spread1;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread2 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -83vh;
        left: 186vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(2) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.2);
    width: 27px;
    height: 27px;
    animation-delay: 0.2s;
    animation-name: spread2;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread3 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 63vh;
        left: 58vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(3) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.1);
    width: 24px;
    height: 24px;
    animation-delay: 0.3s;
    animation-name: spread3;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread4 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -192vh;
        left: -95vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(4) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.9);
    width: 46px;
    height: 46px;
    animation-delay: 0.4s;
    animation-name: spread4;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread5 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 29vh;
        left: -199vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(5) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 1);
    width: 41px;
    height: 41px;
    animation-delay: 0.5s;
    animation-name: spread5;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread6 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 42vh;
        left: 186vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(6) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 1);
    width: 20px;
    height: 20px;
    animation-delay: 0.6s;
    animation-name: spread6;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread7 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 62vh;
        left: -46vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(7) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.4);
    width: 24px;
    height: 24px;
    animation-delay: 0.7s;
    animation-name: spread7;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread8 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -62vh;
        left: -2vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(8) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.7);
    width: 25px;
    height: 25px;
    animation-delay: 0.8s;
    animation-name: spread8;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread9 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 163vh;
        left: 89vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(9) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 1);
    width: 36px;
    height: 36px;
    animation-delay: 0.9s;
    animation-name: spread9;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread10 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -120vh;
        left: 171vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(10) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.2);
    width: 41px;
    height: 41px;
    animation-delay: 1s;
    animation-name: spread10;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread11 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 184vh;
        left: -116vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(11) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.1);
    width: 25px;
    height: 25px;
    animation-delay: 1.1s;
    animation-name: spread11;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread12 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -172vh;
        left: -137vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(12) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.6);
    width: 32px;
    height: 32px;
    animation-delay: 1.2s;
    animation-name: spread12;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread13 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 167vh;
        left: -190vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(13) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.6);
    width: 35px;
    height: 35px;
    animation-delay: 1.3s;
    animation-name: spread13;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread14 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -196vh;
        left: 126vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(14) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.9);
    width: 33px;
    height: 33px;
    animation-delay: 1.4s;
    animation-name: spread14;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread15 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 122vh;
        left: 131vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(15) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.7);
    width: 25px;
    height: 25px;
    animation-delay: 1.5s;
    animation-name: spread15;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread16 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -83vh;
        left: -120vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(16) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.2);
    width: 34px;
    height: 34px;
    animation-delay: 1.6s;
    animation-name: spread16;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread17 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 132vh;
        left: -156vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(17) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.1);
    width: 35px;
    height: 35px;
    animation-delay: 1.7s;
    animation-name: spread17;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread18 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 20vh;
        left: 53vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(18) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.1);
    width: 31px;
    height: 31px;
    animation-delay: 1.8s;
    animation-name: spread18;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread19 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 142vh;
        left: -89vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(19) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.6);
    width: 39px;
    height: 39px;
    animation-delay: 1.9s;
    animation-name: spread19;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread20 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -125vh;
        left: -126vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(20) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.5);
    width: 42px;
    height: 42px;
    animation-delay: 2s;
    animation-name: spread20;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread21 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 41vh;
        left: 129vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(21) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.6);
    width: 25px;
    height: 25px;
    animation-delay: 2.1s;
    animation-name: spread21;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread22 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -4vh;
        left: 156vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(22) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.1);
    width: 23px;
    height: 23px;
    animation-delay: 2.2s;
    animation-name: spread22;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread23 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 71vh;
        left: -88vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(23) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 1);
    width: 37px;
    height: 37px;
    animation-delay: 2.3s;
    animation-name: spread23;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread24 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -12vh;
        left: -53vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(24) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 1);
    width: 43px;
    height: 43px;
    animation-delay: 2.4s;
    animation-name: spread24;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread25 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 4vh;
        left: -53vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(25) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.1);
    width: 27px;
    height: 27px;
    animation-delay: 2.5s;
    animation-name: spread25;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread26 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -66vh;
        left: 175vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(26) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.9);
    width: 45px;
    height: 45px;
    animation-delay: 2.6s;
    animation-name: spread26;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread27 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 193vh;
        left: 35vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(27) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.4);
    width: 20px;
    height: 20px;
    animation-delay: 2.7s;
    animation-name: spread27;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread28 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -150vh;
        left: -34vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(28) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.4);
    width: 41px;
    height: 41px;
    animation-delay: 2.8s;
    animation-name: spread28;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread29 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 45vh;
        left: -70vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(29) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.5);
    width: 33px;
    height: 33px;
    animation-delay: 2.9s;
    animation-name: spread29;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread30 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 113vh;
        left: 58vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(30) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.8);
    width: 36px;
    height: 36px;
    animation-delay: 3s;
    animation-name: spread30;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread31 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 151vh;
        left: -121vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(31) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.6);
    width: 23px;
    height: 23px;
    animation-delay: 3.1s;
    animation-name: spread31;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread32 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -18vh;
        left: -46vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(32) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.4);
    width: 40px;
    height: 40px;
    animation-delay: 3.2s;
    animation-name: spread32;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread33 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 168vh;
        left: 43vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(33) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.4);
    width: 24px;
    height: 24px;
    animation-delay: 3.3s;
    animation-name: spread33;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread34 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -108vh;
        left: 73vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(34) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.1);
    width: 48px;
    height: 48px;
    animation-delay: 3.4s;
    animation-name: spread34;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread35 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 160vh;
        left: -141vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(35) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.2);
    width: 22px;
    height: 22px;
    animation-delay: 3.5s;
    animation-name: spread35;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread36 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -30vh;
        left: -27vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(36) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.5);
    width: 40px;
    height: 40px;
    animation-delay: 3.6s;
    animation-name: spread36;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread37 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 188vh;
        left: -113vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(37) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.4);
    width: 38px;
    height: 38px;
    animation-delay: 3.7s;
    animation-name: spread37;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread38 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -138vh;
        left: 188vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(38) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 1);
    width: 34px;
    height: 34px;
    animation-delay: 3.8s;
    animation-name: spread38;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread39 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 188vh;
        left: 196vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(39) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.9);
    width: 35px;
    height: 35px;
    animation-delay: 3.9s;
    animation-name: spread39;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread40 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -73vh;
        left: -80vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(40) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.7);
    width: 28px;
    height: 28px;
    animation-delay: 4s;
    animation-name: spread40;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread41 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 12vh;
        left: -196vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(41) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.4);
    width: 24px;
    height: 24px;
    animation-delay: 4.1s;
    animation-name: spread41;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread42 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 149vh;
        left: 124vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(42) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.3);
    width: 47px;
    height: 47px;
    animation-delay: 4.2s;
    animation-name: spread42;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread43 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 114vh;
        left: -142vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(43) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.7);
    width: 40px;
    height: 40px;
    animation-delay: 4.3s;
    animation-name: spread43;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread44 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -55vh;
        left: -166vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(44) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.9);
    width: 44px;
    height: 44px;
    animation-delay: 4.4s;
    animation-name: spread44;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread45 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 169vh;
        left: 177vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(45) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 1);
    width: 46px;
    height: 46px;
    animation-delay: 4.5s;
    animation-name: spread45;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread46 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -65vh;
        left: 181vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(46) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.1);
    width: 49px;
    height: 49px;
    animation-delay: 4.6s;
    animation-name: spread46;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread47 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 161vh;
        left: -78vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(47) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.9);
    width: 41px;
    height: 41px;
    animation-delay: 4.7s;
    animation-name: spread47;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread48 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: -27vh;
        left: -185vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(48) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.6);
    width: 34px;
    height: 34px;
    animation-delay: 4.8s;
    animation-name: spread48;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes spread49 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        top: 138vh;
        left: -125vw;
   }
}
#spread:checked ~ .wrapper .dot:nth-child(49) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255, 0.5);
    width: 23px;
    height: 23px;
    animation-delay: 4.9s;
    animation-name: spread49;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
