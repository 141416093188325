/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html{
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.lines {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 85vw;
    z-index: -1;
}

.lines .line {
    width: 1px;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
    position: relative;
}

.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(75%, #fff), to(#fff));
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 75%, #fff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    opacity: 0.4;
}

.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.lines .line:nth-child(2)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

.lines .line:nth-child(4)::after {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}

.lines .line:nth-child(5)::after {
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
}

.lines .line:nth-child(6)::after {
    -webkit-animation-delay: 6s;
    animation-delay: 6s;
}

.lines .line:nth-child(7)::after {
    -webkit-animation-delay: 7s;
    animation-delay: 7s;
}

.lines .line:nth-child(8)::after {
    -webkit-animation-delay: 8s;
    animation-delay: 8s;
}

.lines .line:nth-child(9)::after {
    -webkit-animation-delay: 9s;
    animation-delay: 9s;
}

.lines .line:nth-child(10)::after {
    -webkit-animation-delay: 10s;
    animation-delay: 10s;
}

.animation-bounce{
    animation: logo-bounce 20s infinite;
}

/* Animation */

@-webkit-keyframes run {
    0% {
        top: -50%;
    }
    100% {
        top: 110%;
    }
}

@keyframes run {
    0% {
        top: -50%;
    }
    100% {
        top: 110%;
    }
}

@keyframes logo-bounce {
    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
        transform: translateY(-10px);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateY(10px);
    }
}

main {
    width: 100%;
    height: 100%;
    background: url(../public/images/global/landing-bg.png);
    background-position: center top;
    background-size: cover;
}

.spin-slow,
.logobox .logo-round{
    animation-name: spin-slow;
    animation-duration: 15000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.shadow-effect{
    box-shadow: inset 5px -8px 100px 15px rgba(55,130,130,0.33);
    -webkit-box-shadow: inset 5px -8px 100px 15px rgba(55,130,130,0.33);
    -moz-box-shadow: inset 5px -8px 100px 15px rgba(55,130,130,0.33);
    -o-box-shadow: inset 5px -8px 100px 15px rgba(55,130,130,0.33);
}

.shadow-effect-2{
    box-shadow: inset 5px -8px 100px 15px rgba(234,190,58,0.33);
    -webkit-box-shadow: inset 5px -8px 100px 15px rgba(234,190,58,0.33);
    -moz-box-shadow: inset 5px -8px 100px 15px rgba(234,190,58,0.33);
    -o-box-shadow: inset 5px -8px 100px 15px rgba(234,190,58,0.33);
}

.shadow-effect-3{
    box-shadow: inset 5px -8px 100px 15px rgba(223,51,153,0.15);
    -webkit-box-shadow: inset 5px -8px 100px 15px rgba(223,51,153,0.15);
    -moz-box-shadow: inset 5px -8px 100px 15px rgba(223,51,153,0.15);
    -o-box-shadow: inset 5px -8px 100px 15px rgba(223,51,153,0.15);
}

.shadow-effect-4{
    box-shadow: inset 5px -8px 100px 15px rgba(57,143,217,0.05);
    -webkit-box-shadow: inset 5px -8px 100px 15px rgba(57,143,217,0.05);
    -moz-box-shadow: inset 5px -8px 100px 15px rgba(57,143,217,0.05);
    -o-box-shadow: inset 5px -8px 100px 15px rgba(57,143,217,0.05);
}

/* Animation */
@keyframes spin-slow {
    from{transform:rotate(0)}
    to{transform:rotate(360deg)}
} 
/* Animation */

.roadmap-section .slick-list .slick-slide .single-section {
    position: relative;
}
.roadmap-section .slick-list .slick-slide:nth-child(odd) .single-section{
    margin-bottom: 84px;
}
.roadmap-section .slick-list .slick-slide:nth-child(even) .single-section{
    margin-top: 84px;
}
.roadmap-section .slick-list .slick-slide .single-section:before {
    content: "";
    display: block;
    width: 3px;
    height: 40px;
    @apply bg-red-900 bg-opacity-20;
    position: absolute;
}
.roadmap-section .slick-list .slick-slide:nth-child(odd) .single-section:before {
    top: auto;
    bottom: -40px;
}
.roadmap-section .slick-list .slick-slide:nth-child(even) .single-section:before {
    top: -40px;
}

.slick-arrow .slick-arrow {
    top: -40px;
}
.slick-arrow .slick-arrow:before{
    font-size: 26px;
}
.slick-arrow .slick-arrow.slick-prev{
    left: auto;
    right: 10px;
}
.roadmap-section .slick-dots{
    display: none;
}

.wide.bg-black .list{
    background-color: #050B11;
}
.wide.bg-black .option.focus,
.wide.bg-black .option.selected.focus{
    background-color: #303030;
}
.wide.bg-black .option:hover, 
.wide.bg-black .option.focus, 
.wide.bg-black .option.selected.focus{
    background-color: #000000;
}
.slick-arrow .slick-arrow.slick-prev{
    right: 40px;
}
.slick-arrow .slick-arrow.slick-next{
    right: 0px;
}

/* Timer */
.show-counter .countdown p {
    font-size: 60px;
    @apply text-white;
}
.show-counter .countdown span {
    font-size: 18px;
    letter-spacing: 3px;
    text-transform: uppercase;
    @apply text-sky-300;
}
/* Timer */

@media(max-width:767px){
    .slick-arrow .slick-arrow.slick-prev{
        right: 60px;
    }
    .slick-arrow .slick-arrow.slick-next{
        right: 30px;
    }
    .lines .line:nth-child(1)::after {
        display: none;
    }
    .roadmap-section .slick-list .slick-slide .single-section:before{
        display: none;
    }
    .roadmap-section .slick-list .slick-slide:nth-child(odd) .single-section,
    .roadmap-section .slick-list .slick-slide:nth-child(even) .single-section{
        margin: 10px 0;
    }
    .lines .line{
        opacity: 0.5;
    }
    .show-counter .countdown p{
        font-size: 22px;
    }
    .show-counter .countdown span{
        font-size: 12px;
        letter-spacing: 1px;
    }
}